import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { getInvRecSupportFile, getInvRecSupportFileList } from "../api"
import { downloadFile as downloadBlob } from "../lib/util"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 350,
		backgroundColor: theme.palette.background.paper,
		position: "relative",
		overflow: "auto",
		maxHeight: 150,
	},
	listSection: {
		backgroundColor: "inherit",
	},
	ul: {
		backgroundColor: "inherit",
		padding: 0,
	},
}))

export default function (props: any) {
	const classes = useStyles()
	const [supportFiles, setSupportFiles] = React.useState<any | undefined>()
	const invoiceRecID = props.invoiceRecID

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (props.refresh) {
			loadPageInfo()
		}
	}, [props.refresh])

	const loadPageInfo = () => {
		getInvRecSupportFileList(invoiceRecID, setSupportFiles)
	}


	const handleDownloadClick = async (file_id: number, file_name: string) => {

		await getInvRecSupportFile(file_id)
			.then((data) => data.blob())
			.then((blob) => {
				downloadBlob(blob, file_name)
			})
	}

	return (
		<List className={classes.root} subheader={<li />}>
			{supportFiles?.map((file) => (
				<li key={file.id_} className={classes.listSection}>
					<ListItemIcon>
						<React.Fragment>
							<IconButton
								onClick={() => {
									handleDownloadClick(file.id_, file.file_name)
								}}
								size="small">
								<span className="material-icons">insert_drive_file</span>
								{file.file_name}
							</IconButton>
						</React.Fragment>
					</ListItemIcon>
				</li>
			))}
		</List>
	)
}
